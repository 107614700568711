var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.province
    ? _c(
        "v-container",
        { staticClass: "warehouse-province" },
        [
          _c("h1", { staticClass: "text-center" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("warehouse.province.title", {
                    province: _vm.province.name
                  })
                ) +
                " "
            )
          ]),
          _c("p", { staticClass: "text-center" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("warehouse.province.subtitle", {
                    province: _vm.province.name
                  })
                ) +
                " "
            )
          ]),
          _c(
            "GmapMap",
            {
              ref: "mapRef",
              staticClass: "warehouse-province-map mb-6",
              style: { width: _vm.mapWidth, height: _vm.mapHeight },
              attrs: {
                center: {
                  lat: 45.410119194768654,
                  lng: 11.881289090441028
                },
                zoom: 8,
                options: {
                  zoomControl: true,
                  mapTypeControl: false,
                  scaleControl: false,
                  streetViewControl: false,
                  rotateControl: false,
                  fullscreenControl: false,
                  disableDefaultUI: false
                }
              }
            },
            [
              _vm._l(_vm.warehouses, function(warehouse, i) {
                return _c("GmapMarker", {
                  key: warehouse.warehouseId,
                  ref: "warehousMarker_" + warehouse.warehouseId,
                  refInFor: true,
                  attrs: {
                    position:
                      _vm.google &&
                      new _vm.google.maps.LatLng(
                        warehouse.address.latitude,
                        warehouse.address.longitude
                      ),
                    clickable: true,
                    icon: "/img_layout/map/negozi.png"
                  },
                  on: {
                    click: function($event) {
                      return _vm.toggleInfoWindow(warehouse, i)
                    }
                  }
                })
              }),
              _c(
                "gmap-info-window",
                {
                  attrs: {
                    options: _vm.infoOptions,
                    position: _vm.infoWindowPos,
                    opened: _vm.infoWinOpen
                  },
                  on: {
                    closeclick: function($event) {
                      $event.stopPropagation()
                      _vm.infoWinOpen = false
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.selectedWarehouse) + " Contenuto ")]
              )
            ],
            2
          ),
          _c(
            "v-row",
            { staticClass: "warehouses" },
            _vm._l(_vm.warehouses, function(warehouse) {
              return _c(
                "v-col",
                {
                  key: warehouse.warehouseId,
                  attrs: { cols: "12", sm: "6", md: "4" }
                },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "warehouse-card rounded-md px-1 pb-3",
                      attrs: { outlined: "" }
                    },
                    [
                      _c("v-card-title", [
                        _c(
                          "h4",
                          {
                            staticClass:
                              "primary--text font-weight-bold text-uppercase"
                          },
                          [_vm._v(" " + _vm._s(warehouse.name) + " ")]
                        )
                      ]),
                      _c(
                        "v-card-text",
                        { staticClass: "warehouse-card-address" },
                        [
                          _c("address", [
                            _vm._v(
                              " " +
                                _vm._s(warehouse.address.address1) +
                                ", " +
                                _vm._s(warehouse.address.addressNumber)
                            ),
                            _c("br"),
                            _vm._v(
                              " " +
                                _vm._s(warehouse.address.city) +
                                " (" +
                                _vm._s(warehouse.address.province) +
                                ") "
                            )
                          ])
                        ]
                      ),
                      _c(
                        "v-card-actions",
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "primary",
                              attrs: {
                                elevation: "0",
                                "x-large": "",
                                block: "",
                                to: `/negozi-e-volantini/negozi/${warehouse.warehouseId}`
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("warehouse.province.cardBtn"))
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            }),
            1
          ),
          _c(
            "div",
            { staticClass: "text-center mt-4" },
            [
              _c("h2", { staticClass: "text-center" }, [
                _vm._v(_vm._s(_vm.$t("warehouse.province.subtitle2")))
              ]),
              _vm._l(_vm.provinces, function(province, i) {
                return _c(
                  "h4",
                  {
                    key: province.categoryId,
                    staticClass: "d-inline px-2 mr-n3"
                  },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "province-link",
                        attrs: { to: `/${province.slug}` }
                      },
                      [_vm._v(" " + _vm._s(province.name))]
                    ),
                    _vm._v(" " + _vm._s(_vm.printPipe(_vm.provinces, i)) + " ")
                  ],
                  1
                )
              })
            ],
            2
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }